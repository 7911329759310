import authService from '../../api/auth';
import {loading, handleError, alert, storeLocal, destroyLocal, getLocal} from '@/state/generators/helpers';

export const state = {
    permissions: getLocal('permissions') || {},
    admin: getLocal('admin') || false,
    errors: {},
    loading: false,
    service: authService(''),
    authenticated: !!sessionStorage.getItem('authenticated'),
}

export const mutations = {
    'SET_AUTHENTICATED': function (state, boolean) {
        state.authenticated = boolean
    },

    'SET_LOADING': function (state, boolean = true) {
        state.loading = boolean
    },

    'SET_ERRORS': function(state, errors) {
        state.errors = errors
    },

    'SET_ERROR': function(state, error) {
        const errors = Object.assign({}, state.errors)

        Object.keys(error).forEach(key => {
            errors[key]
                ? errors[key].push(error[key])
                : errors[key] = Array(error[key])
        })

        state.errors = errors
    },

    'SET_PERMISSIONS': function (state, permissions) {
        storeLocal('permissions', permissions)
        state.permissions = permissions
    },

    'SET_ADMIN': function (state, boolean) {
        storeLocal('admin', boolean)
        state.admin = boolean
    },
}

export const getters = {
    loading: function (state) {
        return !!state.loading
    },

    authenticated: function (state) {
        return state.authenticated
    },

    states: function (state) {
        const states = {};

        Object.keys(state.errors).forEach(key => {
                states[key] = false
            }
        )

        return states
    },

    errors: function (state) {
        const errors = Object.assign({}, (state.errors || {}))

        Object.keys(errors).forEach(key => {
            errors[key] = errors[key].join(' ')
        })

        return errors;
    },

    permissions: function (state) {
        return JSON.parse(JSON.stringify(state.permissions))
    },

    can: (state, getters) => (permission) => {
        if (getters.admin) {
            return getters.admin
        }

        return state.permissions.includes(permission)
    },

    admin: function (state) {
        return !!state.admin
    },
}

export const actions = {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    // eslint-disable-next-line no-unused-vars
    init({ dispatch }) {
        dispatch('validate')
    },

    addError: function ({commit}, payload) {
        commit('SET_ERROR', payload)
    },

    login: function ({state, commit, dispatch}, payload) {
        loading(commit)

        /*if (getters.authenticated) {
            dispatch('logout')
            /!*loading(commit, false)
            const message = this.$t('auth.logged_in').ucFirst()
            alert(commit, message, 'warning', 'warning')
            throw new Error(message)*!/
        }*/

        destroyLocal('accessToken')
        destroyLocal('permissions')
        destroyLocal('admin')
        commit('SET_PERMISSIONS', {})
        commit('SET_ADMIN', false)

        return state.service.csrf().then(() => {
            return dispatch('logout').then(() => {
                return state.service.csrf().then(() => {
                    return state.service.login(payload).then(response => {
                        commit('SET_AUTHENTICATED', true)
                        storeLocal('authenticated', true)
                        dispatch('trans/setLocale', {}, {root: true})
                        dispatch('user/authenticated', {}, {root: true})
                        dispatch('permissions')
                        loading(commit, false)
                        return response
                    }).catch((e) => {
                        if (e.response.status === 302) {
                            commit('SET_AUTHENTICATED', true)
                            storeLocal('authenticated', true)
                            dispatch('trans/setLocale', {}, {root: true})
                            dispatch('user/authenticated', {}, {root: true})
                            dispatch('permissions')
                            loading(commit, false)
                        } else {
                            handleError(e, commit)
                        }
                    })
                })
            })
        })
    },

    logout: function ({state, commit, dispatch}) {
        loading(commit)
        commit('SET_PERMISSIONS', {})
        commit('SET_ADMIN', false)

        return state.service.logout().then(response => {
            commit('SET_AUTHENTICATED', false);
            dispatch('user/setAuthenticated', {}, {root: true})
            destroyLocal('authenticated')
            destroyLocal('permissions')
            destroyLocal('admin')
            this.reset()
            loading(commit, false)
            return response
        }).catch((e) => {
            destroyLocal('authenticated')
            destroyLocal('permissions')
            destroyLocal('admin')
            this.reset()
            handleError(e, commit)
        })
    },

    forgotPassword: function ({state, commit, getters}, payload) {
        loading(commit)

        if (getters.authenticated) {
            loading(commit, false)
            const message = this.$t('auth.logged_in').ucFirst()
            alert(commit, message, 'warning', 'warning')
            throw new Error(message)
        }
        return state.service.csrf().then(() => {
            return state.service.forgotPassword(payload).then((response) => {
                storeLocal('email', payload.email)
                loading(commit, false)
                return response.data
            }).catch((e) => {
                handleError(e, commit)
            })
        })
    },

    resetPassword: function ({state, commit, getters}, payload) {
        loading(commit)

        if (getters.authenticated) {
            loading(commit, false)
            return getters.user
        }

        const data = Object.assign({}, {email: getLocal('email')}, payload)

        return state.service.resetPassword(data).then((response) => {
            destroyLocal('email')
            loading(commit, false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    register: function ({state, commit, getters}, payload) {
        loading(commit)

        if (getters.authenticated) {
            loading(commit, false)
            const message = this.$t('auth.logged_in').ucFirst()
            alert(commit, message, 'warning', 'warning')
            throw new Error(message)
        }

        return state.service.csrf().then(() => {
            return state.service.register(payload).then((response) => {
                loading(commit, false)
                return response.data
            }).catch((e) => {
                handleError(e, commit)
            })
        })
    },

    permissions: function ({commit, state}) {
        loading(commit)

        return state.service.permissions().then(response => {
            loading(commit, false)
            commit('SET_PERMISSIONS', response.data)
            if (response.additional && response.additional.admin) {
                commit('SET_ADMIN', response.additional.admin);
            }
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    // Validates the current user's token and refreshes it
    // with new data from the API.
    // eslint-disable-next-line no-unused-vars
    validate({ getters, dispatch, rootGetters }) {
        if (!rootGetters['user/authenticated']) return Promise.resolve(null)
        if (!getters.permissions) {
            dispatch('permissions')
        }
        return rootGetters['user/authenticated'];
    },
}
