import qs from 'qs';
import axios from './axios'

export default function generateService(endpoint) {

    function fetchList(data) {
        return axios.get(endpoint, { params: data, paramsSerializer: params => qs.stringify(params, { encode: false })  }).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function fetchAll(data) {
        return axios.get(endpoint, { params: data, paramsSerializer: params => qs.stringify(params, { encode: false })  }).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function fetchItem(id) {
        return axios.get(`${endpoint}/${id}`).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function store(data) {
        return axios.post(endpoint, data).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function destroy(id) {
        return axios.delete(`${endpoint}/${id}`).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function update(id, data) {
        return axios.post(`${endpoint}/${id}`, data).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });

        /*return axios.patch(`${endpoint}/${id}`, data, {
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });*/
    }

    function updateSorting(data) {
        return axios.post(`${endpoint}/sorting`, data).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });

        /*return axios.patch(`${endpoint}/${id}`, data, {
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });*/
    }

    return {
        fetchAll,
        fetchList,
        fetchItem,
        store,
        update,
        destroy,
        updateSorting
    }
}
