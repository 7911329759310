// import Vue from "vue";
// import VueRouter from "vue-router";

import {createWebHistory, createRouter} from "vue-router";

import routes from "./routes";
import qs from 'qs'

// import VueMeta from "vue-meta";
import store from "@/state/store";

// Vue.use(VueRouter);

// Vue.use(VueMeta, {
//   // The component option name that vue-meta looks for meta info on.
//   keyName: "page",
// });
const router = createRouter({
    history: createWebHistory(), parseQuery: (query) => {
        return qs.parse(query)
    }, stringifyQuery(query) {
        const result = qs.stringify(query, {encode: false})
        return result || ''
    }, routes, // mode: "history",
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return {x: 0, y: 0};
        }
    },
});

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {

    const authRequired = routeTo.meta.authentication_required
    let authenticated = store.getters["auth/authenticated"] || JSON.parse(localStorage.getItem('authenticated'))
    let navigation = store.getters['menuPoint/auth']
    const checkAuth = navigation[routeTo.path] || routeTo.meta.authorization_required
    const can = store.getters['auth/can']

    //   // If auth isn't required for the route, just continue.
    if (!authRequired) return next();

    if (authRequired) {
        if (!authenticated) {
            return next({name: "login", query: {redirectFrom: routeTo.fullPath}});
        }

        if (checkAuth) {
            const checked = Array.isArray(checkAuth)
                ? checkAuth.every(permission => {
                    return can(permission)
                })
                : can(checkAuth);

            if (!checked) {
                return next({name: "pages-403", query: {redirectFrom: routeTo.fullPath}});
            }
        }

        return next()
    }

    // eslint-disable-next-line no-unused-vars
    // eslint-disable-next-line no-inner-declarations
    /*function redirectToLogin() {
        // Pass the original route to the login component
        next({name: "login", query: {redirectFrom: routeTo.fullPath}});
    }*/

});

// router.beforeResolve(async (routeTo, routeFrom, next) => {
// Create a `beforeResolve` hook, which fires whenever
// `beforeRouteEnter` and `beforeRouteUpdate` would. This
// allows us to ensure data is fetched even when params change,
// but the resolved route does not. We put it in `meta` to
// indicate that it's a hook we created, rather than part of
// Vue Router (yet?).
// try {
//   // For each matched route...
//   for (const route of routeTo.matched) {
//     await new Promise((resolve, reject) => {
//       // If a `beforeResolve` hook is defined, call it with
//       // the same arguments as the `beforeEnter` hook.
//       if (route.meta && route.meta.beforeResolve) {
//         route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
//           // If the user chose to redirect...
//           if (args.length) {
//             // If redirecting to the same route we're coming from...
//             // Complete the redirect.
//             next(...args);
//             reject(new Error("Redirected"));
//           } else {
//             resolve();
//           }
//         });
//       } else {
//         // Otherwise, continue resolving the route.
//         resolve();
//       }
//     });
//   }
//   // If a `beforeResolve` hook chose to redirect, just return.
// } catch (error) {
//   return;
// }

// // If we reach this point, continue resolving the route.
// next();
// });

export default router;
