<script>
import {mapGetters} from "vuex";
export default {
    computed: {
        ...mapGetters({
            translations: 'trans/translations',
            aliases: 'trans/aliases',
            locale: 'trans/locale'
        })
    },

    methods: {
        search: function (aliases, routes, parent) {
            for (let prop in aliases) {
                routes.map(route => {
                    if (prop === route.path) {
                        if (aliases[prop] === route.path) {
                            // do nothing
                        } else if (route.alias && route.alias.length && !route.alias.includes(aliases[prop])) {
                            route.alias = [...route.alias, aliases[prop]];
                        } else if (!route.alias || (route.alias && !route.alias.length)) {
                            route.alias = [aliases[prop]];
                        }
                        if (parent) {
                            this.$router.addRoute(parent, route);
                        } else {
                            this.$router.addRoute(route)
                        }
                    }
                    if (route.children && route.children.length) {
                        this.search(aliases, route.children, route.name);
                    }
                })
            }
        },
    },

    watch: {
        locale: function (locale) {
            this.$i18n.locale = locale
            this.$store.dispatch('trans/setLocale')
            this.$store.dispatch('menuPoint/arranged')
        },

        translations: {
            deep: true,
            handler: function (translations) {
                this.$i18n.setLocaleMessage(this.locale, translations)
            },
        },

        aliases: {
            deep: true,
            handler: function (aliases) {
                const routes = this.$router.options.routes;

                this.search(aliases, routes)

                this.$router.push(this.$route.path)
            },
        }
    },

    created() {
        this.$store.dispatch('trans/translations')
        this.$store.dispatch('trans/aliases')
    }
}
</script>

