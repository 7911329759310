import axios from './axios'

export default function (endpoint) {

    function updateSorting(data) {
        return axios.post(`${endpoint}/sorting`, data).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    return {
        updateSorting
    }
}
