<script>
import {mapGetters} from "vuex";
import Swal from "sweetalert2";

export default {
    name: "alerts",

    computed: {
        ...mapGetters({
            alerts: 'app/alerts'
        })
    },

    methods: {
        alert(message, variant, icon = 'info', timer = 2500) {
            Swal.fire({
                position: "top-end",
                icon: icon,
                title: message,
                showConfirmButton: false,
                timer: timer,
                toast: true,
                background: '#fff',
                customClass: {
                    popup: ' bg-soft-' + variant,
                    title: 'text-' + variant,
                    cancelButton: 'btn btn-primary btn-md ms-1',
                    header: 'bg-' + variant,
                    icon: 'text-danger border-danger'
                },
            });
        },
    },

    watch: {
        'alerts': function (val, oldVal) {
            const length = val.length, oldLength = oldVal.length;

            if (length > oldLength) {
                val.slice(length - (length - oldLength)).forEach(item => {
                    this.alert(item.message, item.style, item.icon, item.countdown);
                });
            }
        },
    },
}
</script>

<style scoped>

</style>
