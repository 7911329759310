import service from '@/api/roleCategory';
import {loading, handleError} from '@/state/generators/helpers';

export const state = {
    loading: false,
    service: service('roleCategories')
}

export const getters = {

}

export const mutations = {
    'SET_LOADING': function (state, boolean = true) {
        state.loading = boolean
    },
}

export const actions = {
    roles: function ({commit, state}, payload) {
        loading(commit)

        const id = payload.id
        delete payload.id

        return state.service.syncRoles(id, payload).then(response => {
            loading(commit, false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },
}
