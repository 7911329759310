import axios from './axios'

function authService(endpoint) {

    function csrf() {
        return axios.get(`csrf-cookie`).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function login(data) {
        return axios.post(`${endpoint}/login`, data).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function logout() {
        return axios.post(`${endpoint}/logout`).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function register(data) {
        return axios.post(`${endpoint}/register`, data).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function forgotPassword(data) {
        return axios.post(`${endpoint}/forgot-password`, data).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function resetPassword(data) {
        return axios.post(`${endpoint}/reset-password`, data).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function permissions() {
        return axios.get(`${endpoint}/permissions`).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    return {
        csrf,
        login,
        logout,
        register,
        forgotPassword,
        resetPassword,
        permissions
    }
}

export default authService
