import service from '@/api/trans';
import {loading, handleError,storeLocal, destroyLocal, getLocal} from '@/state/generators/helpers';

export const state = {
    loading: false,
    translations: getLocal('translations') || {},
    aliases: getLocal('aliases') || {},
    service: service('translations'),
    locale: 'hu'
}

function getKeys(obj) {
    const keys = [];

    const walk = (o, parent = null) => {
        for (const k in o) {
            const current = parent ? parent + '.' + k : k;
            if (typeof o[k] !== 'object') {
                keys.push(current);
            }

            // This checks if the current value is an Object
            if (Object.prototype.toString.call(o[k]) === '[object Object]') {
                walk(o[k], current);
            }
        }
    }

    walk(obj);

    return keys;
}

export const getters = {
    loading: (state) => {
        return state.loading
    },
    translations: (state) => {
        return JSON.parse(JSON.stringify(state.translations))
    },
    aliases: (state) => {
        return JSON.parse(JSON.stringify(state.aliases))
    },
    locale: (state) => {
        return state.locale
    },
    keys: (state) => {
        return getKeys(state.translations)
    }
}

export const mutations = {
    'SET_LOADING': function (state, boolean = true) {
        state.loading = boolean
    },

    'SET_TRANSLATIONS': function (state, translations) {
        state.translations = translations
        translations ? storeLocal('translations', translations) : destroyLocal('translations')
    },

    'SET_ALIASES': function (state, aliases) {
        state.aliases = aliases
        aliases ? storeLocal('aliases', aliases) : destroyLocal('aliases')
    },

    'SET_LOCALE': function (state, locale) {
        state.locale = locale
    },
}

export const actions = {
    translations: function ({commit, state}) {
        loading(commit)

        return state.service.fetchAll(state.locale).then(data => {
            commit('SET_TRANSLATIONS', data)
            loading(commit, false)
            return data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    aliases: function ({commit, state}) {
        loading(commit)

        return state.service.fetchAliases(state.locale).then(data => {
            commit('SET_ALIASES', data)
            loading(commit, false)
            return data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    locale: function ({commit, dispatch}, locale) {
        commit('SET_LOCALE', locale)
        dispatch('aliases')
        return dispatch('translations')
    },

    setLocale: function ({commit, state}) {
        loading(commit)

        return state.service.setLocale(state.locale).then(data => {
            loading(commit, false)
            return data
        }).catch((e) => {
            handleError(e, commit)
        })
    },
}
