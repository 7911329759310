import apiServiceGenerator from "@/api/generator";
import {changes, loading, alert, handleError} from './helpers'

export default function (endpoint) {

    return {
        namespaced: true,

        state: {
            loading: false,
            pager: {},
            additional: {},
            list: [],
            all: [],
            item: {},
            errors: {},
            service: apiServiceGenerator(endpoint)
        },

        getters: {
            loading: function (state) {
                return !!state.loading
            },
            pager: function (state) {
                return JSON.parse(JSON.stringify(state.pager))
            },
            all: function (state) {
                return JSON.parse(JSON.stringify(state.all))
            },
            list: function (state) {
                return state.list
            },
            item: function (state) {
                return JSON.parse(JSON.stringify(state.item))
            },
            states: function (state) {
                const states = {};

                Object.keys(state.errors).forEach(key => {
                        states[key] = false
                    }
                )

                return states
            },

            errors: function (state) {
                const errors = Object.assign({}, (state.errors || {}))

                Object.keys(errors).forEach(key => {
                    errors[key] = errors[key].join(' ')
                })

                return errors;
            },
        },

        mutations: {
            'SET_LOADING': function (state, boolean = true) {
                state.loading = boolean
            },

            'SET_ALL': function (state, all) {
                state.all = all
            },

            'SET_LIST': function (state, list) {
                state.list = list
            },

            'SET_ITEM': function (state, item = {}) {
                state.item = item
            },

            'ADD_ITEM': function (state, item) {
                if (!state.list) {
                    state.list = [];
                }

                state.list.unshift(item)
            },

            'PUSH_ITEM': function (state, item) {
                if (!state.list) {
                    state.list = []
                }

                state.list.push(item)
            },

            'REPLACE_ITEM': function (state, item) {
                const index = state.list.findIndex(item => item.id === item.id);

                if (index !== -1) {
                    state.list[index] = item
                }

                const allIndex = state.all.findIndex(item => item.id === item.id);

                if (allIndex !== -1) {
                    state.all[index] = item
                }
            },

            'REMOVE_ITEM': function (state, id) {
                const index = state.list.findIndex(item => item.id === id);

                if (index !== -1) {
                    state.list.splice(index, 1)
                }
            },

            'SET_PAGER': function (state, pager = {}) {
                state.pager = pager
            },

            'SET_ADDITIONAL': function (state, data = {}) {
                state.additional = data
            },

            'SET_ERRORS'(state, errors) {
                state.errors = errors
            },

            'SET_ERROR'(state, error) {
                const errors = Object.assign({}, state.errors)

                Object.keys(error).forEach(key => {
                    errors[key]
                        ? errors[key].push(error[key])
                        : errors[key] = Array(error[key])
                })

                state.errors = errors
            }
        },

        actions: {
            all: function ({commit, state}, force) {
                if (!state.all || !state.all.length || force) {
                    loading(commit)

                    let data = typeof force === 'object' ? force : undefined

                    return state.service.fetchAll(data).then(response => {
                        commit('SET_ALL', response.data)
                        loading(commit, false)
                        return response.data
                    }).catch((e) => {
                        handleError(e, commit)
                    });
                }
            },

            list: function ({commit, state}, filters) {
                loading(commit)

                return state.service.fetchList(filters).then(response => {
                    commit('SET_LIST', response.data)
                    commit('SET_PAGER', response.meta)
                    commit('SET_ADDITIONAL', response.additional)
                    loading(commit, false)
                    return response.data
                }).catch((e) => {
                    handleError(e, commit)
                })
            },

            show: function ({commit, state}, id) {
                loading(commit)

                return state.service.fetchItem(id).then(data => {
                    commit('SET_ITEM', data.data)
                    loading(commit, false)
                    return data
                }).catch((e) => {
                    handleError(e, commit)
                })
            },

            select: function ({commit, state}, id) {
                loading(commit)

                let item = state.list.find(item => item.id === id);

                if (!item) {
                    state.all.find(item => item.id === id)
                }

                if (item) {
                    commit('SET_ITEM', item);
                    loading(commit, false)
                    return item;
                } else {
                    loading(commit, false)
                }
            },

            create: function ({commit, state, dispatch}, payload) {
                loading(commit)

                return state.service.store(payload).then(data => {
                    const model = Object.assign({}, payload, data.data)

                    commit('ADD_ITEM', model)
                    commit('SET_ITEM', model)
                    alert(commit, this.$t('request.creation_is_successful'))
                    commit('SET_ERRORS', {});
                    dispatch('all', true);

                    loading(commit, false)

                    return model
                }).catch((e) => {
                    handleError(e, commit)
                })
            },

            update: function ({commit, state}, payload) {
                loading(commit)

                const { id, data } = changes(payload, state.item)

                const content = Object.assign({_method: 'PUT'}, data)

                return state.service.update(id, content).then(response => {
                    const model = Object.assign({}, data, response.data)

                    commit('REPLACE_ITEM', model);
                    commit('SET_ITEM', model);
                    alert(commit, this.$t('request.save_is_successful'))
                    commit('SET_ERRORS', {});
                    loading(commit, false)

                    return model;
                }).catch((e) => {
                    handleError(e, commit)
                })
            },

            destroy: function ({commit, state}, id) {
                loading(commit)

                return state.service.destroy(id).then(response => {
                    commit('REMOVE_ITEM', id);
                    commit('SET_ITEM', {});
                    alert(commit, this.$t('request.deletion_is_successful'))
                    loading(commit, false)

                    return response;
                }).catch((e) => {
                    handleError(e, commit)
                });
            },

            clearItem: function ({commit}) {
                commit('SET_ITEM')
            },

            clearList: function ({commit}) {
                commit('SET_LIST', [])
                commit('SET_PAGER', {})
                commit('SET_ADDITIONAL', {})
            },

            clearAll: function ({commit}) {
                commit('SET_ALL', [])
            },

            clearErrors: function ({commit}) {
                commit('SET_ERRORS', {})
            },

            clearModule: function ({dispatch}) {
                dispatch('clearItem')
                dispatch('clearList')
                dispatch('clearAll')
                dispatch('clearErrors')
            },

            replaceItem: function ({commit}, item) {
                commit('REPLACE_ITEM', item);
            }
        },
    }
}
