<template>
  <div id="app">
      <translations></translations>
      <alerts></alerts>
      <router-view></router-view>
  </div>
</template>

<script>
import appConfig from "@/app.config";
import {mapGetters} from "vuex";
import Alerts from "@/components/alerts.vue";
import Translations from "./components/translations.vue";

export default {
  name: "AACRM",
  components: {Translations, Alerts},

  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    },
  },

  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'user/authenticated',
      translations: 'trans/translations',
      aliases: 'trans/aliases',
      locale: 'trans/locale'
    })
  },

  created() {
    if (this.authenticated && (!this.user || !this.user.id)) {
      this.$store.dispatch('user/authenticated')
    }
  }
};
</script>
