import service from '@/api/user';
import {loading, alert, handleError, destroyLocal, storeLocal} from '@/state/generators/helpers';

export const state = {
    loading: false,
    authenticated: sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : {},
    service: service('users')
}

export const getters = {
    authenticated: (state) => {
        return JSON.parse(JSON.stringify(state.authenticated))
    },

    firstLetter: (state) => {
        return state.authenticated.id ? state.authenticated.firstname[0] : ''
    }
}

export const mutations = {
    'SET_LOADING': function (state, boolean = true) {
        state.loading = boolean
    },

    'SET_AUTHENTICATED': function (state, user) {
        state.authenticated = user
        user ? storeLocal('user', user) : destroyLocal('user')
    },
}

export const actions = {
    authenticated: function ({commit, state}) {
        loading(commit)

        return state.service.fetchAuthenticated().then(data => {
            commit('SET_AUTHENTICATED', data.data)
            loading(commit, false)
            return data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    setAuthenticated: function ({commit}, user) {
        commit('SET_AUTHENTICATED', user)
    },

    changePassword: function ({commit, state}, passwords) {
        loading(commit)

        const id = passwords.id

        delete passwords.id

        return state.service.changePassword(id, passwords).then(data => {
            loading(commit, false)
            commit('SET_ERRORS', {})
            alert(commit, this.$t('users.password_changed').ucFirst())
            return data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    roles: function ({commit, state}, payload) {
        loading(commit)

        const id = payload.id
        delete payload.id

        return state.service.syncRoles(id, payload).then(response => {
            loading(commit, false)
            alert(commit, this.$t('request.save_is_successful'))
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    roleGroups: function ({commit, state}, payload) {
        loading(commit)

        const id = payload.id
        delete payload.id

        return state.service.syncRoleGroups(id, payload).then(response => {
            loading(commit, false)
            alert(commit, this.$t('request.save_is_successful'))
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },
}
