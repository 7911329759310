import { createI18n } from 'vue-i18n'
import store from '../state/store'

const i18n = createI18n({
    locale: 'hu',
    fallbackLocale: 'en',
    globalInjection: true,
})

store.$t = i18n.global.t
store.$rt = i18n.global.rt
store.$tc = i18n.global.tc
store.$te = i18n.global.te
store.$tm = i18n.global.tm
store.$d = i18n.global.d
store.$n = i18n.global.n

export default i18n
