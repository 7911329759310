import { createStore } from 'vuex'
import modules from './modules'
import { crud, sortable } from "./generator";
const _ = require("lodash");

const crudModules = crud({
  user: 'users',
  menu: 'menus',
  menuPoint: 'menuPoints',
  menuPointCategory: 'menuPointCategories',
  address: 'addresses',
  addressType: 'addressTypes',
  country: 'countries',
  position: 'positions',
  contact: 'contacts',
  companySize: 'companySizes',
  industry: 'industries',
  leadSource: 'leadSources',
  fieldVersion: 'fieldVersions',
  fieldType: 'fieldTypes',
  field: 'fields',
  partner: 'partners',
  streetType: 'streetTypes',
  zipCode: 'zipCodes',
  roleCategory: 'roleCategories',
  role: 'roles',
  roleGroup: 'roleGroups',
  module: 'modules',
})

const sortableModules = sortable({
  field: 'fields'
})

const merged = _.merge(crudModules, sortableModules, modules)

// eslint-disable-next-line no-unused-vars
const store = createStore({
  modules: merged,
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== 'production',
})

store.reset = function () {
  Object.keys(this._actions).forEach(action => {
    if (action.endsWith('clearModule')) {
      this.dispatch(action)
    }
  }, this)
};

export default store;

