import {
    createApp
} from "vue";
import App from "./App.vue";
import * as EvaIcons from "@stefandesu/eva-icons-vue"
import router from "./router";
import ActionCableVue from "actioncable-vue";
import BootstrapVue3 from 'bootstrap-vue-3';
import VueApexCharts from "vue3-apexcharts";
import vClickOutside from "click-outside-vue3"
import Maska from 'maska'
import axios from './api/axios'
import VueAxios from 'vue-axios'
import i18n from './translations/i18n'
import VueCropper from 'vue-cropperjs';
import VueTelInput from 'vue-tel-input';

import 'vue-tel-input/dist/vue-tel-input.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import '@vueform/slider/themes/default.css';
import './assets/scss/custom/plugins/mermaid.min.css'

import store from "./state/store";
import auth from "./auth"

import 'cropperjs/dist/cropper.css';
import "./assets/scss/app.scss";

const websocketOptions = {
    debug: true, debugLevel: "error", connectionUrl: "http://localhost:8080/", connectImmediately: false
};

String.prototype.ucFirst = function () {
    if (!this) {
        return
    }

    if (this.length === 1) {
        return this.toUpperCase();
    } else {
        return this[0].toUpperCase() + this.substr(1);
    }
};

String.prototype.rmValidation = function () {
    if (!this) {
        return
    }

    if (this.indexOf('(\\d+)') !== -1) {
        return this.replace(/\(\\d\+\)/g, '');
    } else if (this.indexOf('*') !== -1) {
        return this.replace(/\*/g, '');
    } else {
        return this;
    }
};

Date.prototype.formattedDate = function () {
    const pad = (number) => {
        return number < 10 ? '0' + String(number) : number
    }

    return [this.getFullYear(), pad(this.getMonth()+1), pad(this.getDate())].join('-')
        + ' ' +
        [pad(this.getHours()), pad(this.getMinutes()), pad(this.getSeconds())].join(':');

};

createApp(App)
    .use(i18n)
    .use(store)
    .use(EvaIcons)
    .use(router)
    .use(BootstrapVue3)
    .use(VueApexCharts)
    .use(vClickOutside)
    .use(Maska)
    .use(ActionCableVue, websocketOptions)
    .use(VueAxios, axios)
    .use(auth)
    .use(VueTelInput)
    .directive('translate', {
        mounted: function (el, binding) {
            if (!binding.value) {
                return
            }

            let text = binding.instance.$t(binding.value)

            if (binding.modifiers.ucFirst) {
                text = text[0].toUpperCase() + text.slice(1)
            }

            if (binding.arg === 'placeholder') {
                el.placeholder = text
            } else {
                el.innerText = text
            }
        }
    })
    .component('VueCropper', VueCropper)
    .mount("#app");

