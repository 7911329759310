import axios from './axios'

function service(endpoint) {

    function fetchAll(locale) {
        return axios.get(`${endpoint}?locale=${locale}`).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function fetchAliases(locale) {
        return axios.get(`${endpoint}/routes?locale=${locale}`).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function setLocale(locale) {
        return axios.post(`${endpoint}/locale`, {locale: locale}).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    return {
        fetchAll,
        fetchAliases,
        setLocale
    }
}

export default service
