import store from "../state/store";

export default {
    install: function (Vue) {
        Vue.config.globalProperties.can = store.getters['auth/can']
        // 1. add global method or property
        /*Vue.myGlobalMethod = function () {
            // some logic ...
        }*/

        // 2. add a global asset
        /*Vue.directive('my-directive', {
            bind (el, binding, vnode, oldVnode) {
                // some logic ...
            }
        })*/

        // 3. inject some component options
        /*Vue.mixin({
            created: function () {
                // some logic ...
            }
            ...
        })*/

        // 4. add an instance method
        /*Vue.prototype.$myMethod = function (methodOptions) {
            // some logic ...
        }*/
    }
}
