import axios from './axios'

function service(endpoint) {

    function syncRoles (id, data) {
        return axios.post(`${endpoint}/${id}/roles`, data).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    return {
        syncRoles
    }
}

export default service
