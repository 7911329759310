export const state = {
    loading: false,
    title: '',
    alerts: []
};
export const getters = {
    loading: function (state) {
        return !!state.loading;
    },
    alerts: function (state) {
        return JSON.parse(JSON.stringify(state.alerts));
    },
    title: function (state) {
        return state.title;
    }
};
export const mutations = {
    'SET_LOADING': function (state, boolean = true) {
        state.loading = boolean;
    },
    'SET_ALERTS': function (state, items) {
        state.alerts = items;
    },
    'ADD_ALERT': function (state, alert) {
        if (!state.alerts) {
            state.alerts = [];
        }
        state.alerts.push(alert);
    },
    'REMOVE_ALERT' (state, index) {
        state.alerts.splice(index, 1);
    },
    'SET_TITLE': function (state, title) {
        state.title = title;
    },
};

export const actions = {
    title: function ({commit}, title) {
        commit('SET_TITLE', title)
    },
};
