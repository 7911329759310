import service from '@/api/field';
import {loading, handleError} from '@/state/generators/helpers';

export const state = {
    loading: false,
    owners: {},
    service: service('owners')
}

export const getters = {
    owners: function (state) {
        return state.owners
    },
}

export const mutations = {
    'SET_LOADING': function (state, boolean = true) {
        state.loading = boolean
    },

    'SET_OWNERS': function (state, owners) {
        const obj = []

        Object.keys(owners).forEach((item) => {
            obj.push({
                translation_key: owners[item],
                model_name: item,
                type:  this.$tc(owners[item], 2).normalize('NFD').replace(/\p{Diacritic}/gu, '')
            })
        })

        state.owners = obj
    },
}

export const actions = {
    owners: function ({commit, state}) {
        loading(commit)

        return state.service.owners(state.locale).then(response => {
            commit('SET_OWNERS', response.data)
            loading(commit, false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    }
}
