export default [
    {
        path: '/',
        name: 'home',
        meta: {
            authentication_required: true,
            authorization_required: null
        },
        component: () => import('../views/layouts/main.vue'),
        children: [
            {
                path: '/partners/my-partners',
                name: 'my-partners',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/partners/my.vue')
            },
            {
                path: '/partners/all',
                name: 'all-partner',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/partners/all.vue')
            },
            {
                path: '/partners/free',
                name: 'free-partner',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/partners/free.vue')
            },
            {
                path: '/partners/my-partners/create/:type?',
                name: 'my-partner-create',
                component: () => import('../views/pages/partners/item.vue')
            },
            {
                path: '/partners/create/:type?',
                name: 'partner-create',
                component: () => import('../views/pages/partners/item.vue')
            },
            {
                path: '/partners/:id(\\d+)/:type?',
                name: 'partner-edit',
                component: () => import('../views/pages/partners/item.vue'),
                redirect: { name: 'partner-base-information'},
                children: [
                    {
                        path: '/partners/:id(\\d+)/base-information/:type?',
                        name: 'partner-base-information',
                        component: () => import('../views/pages/partners/edit.vue')
                    },
                    {
                        path: '/partners/:id(\\d+)/contacts/:type?',
                        name: 'partner-contacts',
                        component: () => import('../views/pages/contacts/manage.vue')
                    },
                    {
                        path: '/partners/:id(\\d+)/addresses/:type?',
                        name: 'partner-addresses',
                        component: () => import('../views/pages/addresses/manage.vue')
                    },
                ]
            },
            {
                path: '/master-data/countries',
                name: 'countries',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/countries/list.vue')
            },
            {
                path: '/master-data/countries/create',
                name: 'country-create',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/countries/item.vue')
            },
            {
                path: '/master-data/countries/:id(\\d+)',
                name: 'country-edit',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/countries/item.vue')
            },
            {
                path: '/master-data/zip-codes',
                name: 'zip-codes',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/zip-codes/list.vue')
            },
            {
                path: '/master-data/zip-codes/create',
                name: 'zip-code-create',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/zip-codes/item.vue')
            },
            {
                path: '/master-data/zip-codes/:id(\\d+)',
                name: 'zip-code-edit',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/zip-codes/item.vue')
            },
            {
                path: '/master-data/street-types',
                name: 'street-types',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/street-types/list.vue')
            },
            {
                path: '/master-data/street-types/create',
                name: 'street-type-create',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/street-types/item.vue')
            },
            {
                path: '/master-data/street-types/:id(\\d+)',
                name: 'street-type-edit',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/street-types/item.vue')
            },
            {
                path: '/master-data/positions',
                name: 'positions',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/positions/list.vue')
            },
            {
                path: '/master-data/positions/create',
                name: 'position-create',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/positions/item.vue')
            },
            {
                path: '/master-data/positions/:id(\\d+)',
                name: 'position-edit',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/positions/item.vue')
            },
            {
                path: '/master-data/company-sizes',
                name: 'company-sizes',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/company-sizes/list.vue')
            },
            {
                path: '/master-data/company-sizes/create',
                name: 'company-size-create',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/company-sizes/item.vue')
            },
            {
                path: '/master-data/company-sizes/:id(\\d+)',
                name: 'company-size-edit',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/company-sizes/item.vue')
            },
            {
                path: '/master-data/industries',
                name: 'industries',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/industries/list.vue')
            },
            {
                path: '/master-data/industries/create',
                name: 'industry-create',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/industries/item.vue')
            },
            {
                path: '/master-data/industries/:id(\\d+)',
                name: 'industry-edit',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/industries/item.vue')
            },
            {
                path: '/master-data/lead-sources',
                name: 'lead-sources',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/lead-sources/list.vue')
            },
            {
                path: '/master-data/lead-sources/create',
                name: 'lead-source-create',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/lead-sources/item.vue')
            },
            {
                path: '/master-data/lead-sources/:id(\\d+)',
                name: 'lead-source-edit',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/lead-sources/item.vue')
            },
            {
                path: '/master-data/address-types',
                name: 'address-types',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/address-types/list.vue')
            },
            {
                path: '/master-data/address-types/create',
                name: 'address-type-create',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/address-types/item.vue')
            },
            {
                path: '/master-data/address-types/:id(\\d+)',
                name: 'address-type-edit',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/address-types/item.vue')
            },
            {
                path: '/master-data/field-types',
                name: 'field-types',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/field-types/list.vue')
            },
            {
                path: '/master-data/field-types/create',
                name: 'field-type-create',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/field-types/item.vue')
            },
            {
                path: '/master-data/field-types/:id(\\d+)',
                name: 'field-type-edit',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/field-types/item.vue')
            },
            {
                path: '/master-data/additional-data/:type?',
                name: 'additional-data',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/additional-data/manager.vue')
            },
            {
                path: '/master-data/additional-data/create/:type?',
                name: 'additional-data-create',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/additional-data/item.vue')
            },
            {
                path: '/master-data/additional-data/:id(\\d+)/:type?',
                name: 'additional-data-edit',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/additional-data/item.vue')
            },
            {
                path: '/system',
                name: 'system',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                }
            },
            {
                path: '/authorization/permissions',
                name: 'roles',
                meta: {
                    authentication_required: true,
                    authorization_required: 'roles.view'
                },
                component: () => import('../views/pages/roles/list.vue')
            },
            {
                path: '/authorization/permissions/create',
                name: 'role-create',
                meta: {
                    authentication_required: true,
                    authorization_required: 'roles.create'
                },
                component: () => import('../views/pages/roles/item.vue')
            },
            {
                path: '/authorization/permissions/:id(\\d+)',
                name: 'role-edit',
                meta: {
                    authentication_required: true,
                    authorization_required: 'roles.update'
                },
                component: () => import('../views/pages/roles/item.vue')
            },
            {
                path: '/authorization/role-groups',
                name: 'role-groups',
                meta: {
                    authentication_required: true,
                    authorization_required: 'role_groups.view'
                },
                component: () => import('../views/pages/role-groups/list.vue')
            },
            {
                path: '/authorization/role-groups/create',
                name: 'role-group-create',
                meta: {
                    authentication_required: true,
                    authorization_required: 'role_groups.create'
                },
                component: () => import('../views/pages/role-groups/item.vue')
            },
            {
                path: '/authorization/role-groups/:id(\\d+)',
                name: 'role-group-edit',
                meta: {
                    authentication_required: true,
                    authorization_required: 'role_groups.update'
                },
                component: () => import('../views/pages/role-groups/item.vue')
            },
            {
                path: '/authorization/role-categories',
                name: 'role-categories',
                meta: {
                    authentication_required: true,
                    authorization_required: 'role_categories.view'
                },
                component: () => import('../views/pages/role-categories/list.vue')
            },
            {
                path: '/authorization/role-categories/create',
                name: 'role-category-create',
                meta: {
                    authentication_required: true,
                    authorization_required: 'role_categories.create'
                },
                component: () => import('../views/pages/role-categories/item.vue')
            },
            {
                path: '/authorization/role-categories/:id(\\d+)',
                name: 'role-category-edit',
                meta: {
                    authentication_required: true,
                    authorization_required: 'role_categories.update'
                },
                component: () => import('../views/pages/role-categories/item.vue')
            },
            {
                path: '/authorization/modules',
                name: 'modules',
                meta: {
                    authentication_required: true,
                    authorization_required: 'modules.view'
                },
                component: () => import('../views/pages/modules/list.vue')
            },
            {
                path: '/authorization/modules/create',
                name: 'module-create',
                meta: {
                    authentication_required: true,
                    authorization_required: 'modules.create'
                },
                component: () => import('../views/pages/modules/item.vue')
            },
            {
                path: '/authorization/modules/:id(\\d+)',
                name: 'module-edit',
                meta: {
                    authentication_required: true,
                    authorization_required: 'modules.update'
                },
                component: () => import('../views/pages/modules/item.vue')
            },
            {
                path: '/authorization/individuals',
                name: 'individuals',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/authorization/matrix.vue')
            },
            {
                path: '/authorization/individual/role-group',
                name: 'individual-role-group',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/authorization/grant-group.vue')
            },
            {
                path: '/users',
                name: 'users',
                meta: {
                    authentication_required: true,
                    authorization_required: 'users.view'
                },
                component: () => import('../views/pages/users/list.vue')
            },
            {
                path: '/users/create',
                name: 'user-create',
                meta: {
                    authentication_required: true,
                    authorization_required: 'users.create'
                },
                component: () => import('../views/pages/users/item.vue')
            },
            {
                path: '/users/:id(\\d+)',
                name: 'user-edit',
                meta: {
                    authentication_required: true,
                    authorization_required: 'users.update'
                },
                component: () => import('../views/pages/users/item.vue')
            },
            {
                path: '/users/profile',
                name: 'users-profile',
                meta: {
                    authentication_required: true,
                    authorization_required: null
                },
                component: () => import('../views/pages/users/profile.vue')
            },
            {
                path: '/menu-system/menus',
                name: 'menus',
                component: () => import('../views/pages/menus/list.vue')
            },
            {
                path: '/menu-system/menus/create',
                name: 'menu-create',
                component: () => import('../views/pages/menus/item.vue')
            },
            {
                path: '/menu-system/menus/:id(\\d+)',
                name: 'menu-edit',
                component: () => import('../views/pages/menus/item.vue')
            },
            {
                path: '/menu-system/menu-point-categories',
                name: 'menu-point-categories',
                component: () => import('../views/pages/menu-point-categories/list.vue')
            },
            {
                path: '/menu-system/menu-point-categories/create',
                name: 'menu-point-category-create',
                component: () => import('../views/pages/menu-point-categories/item.vue')
            },
            {
                path: '/menu-system/menu-point-categories/:id(\\d+)',
                name: 'menu-point-category-edit',
                component: () => import('../views/pages/menu-point-categories/item.vue')
            },
            {
                path: '/menu-system/menu-points',
                name: 'menu-points',
                component: () => import('../views/pages/menu-points/list.vue')
            },
            {
                path: '/menu-system/menu-points/create',
                name: 'menu-point-create',
                component: () => import('../views/pages/menu-points/item.vue')
            },
            {
                path: '/menu-system/menu-points/:id(\\d+)',
                name: 'menu-point-edit',
                component: () => import('../views/pages/menu-points/item.vue')
            },
            {
                path: '/files/:path*',
                name: 'file',
                component: () => import('../views/pages/files/item.vue')
            }
        ]
    },
    {
        path: '/auth/login',
        name: 'login',
        component: () => import('../views/pages/auth/login.vue')
    },
    {
        path: '/auth/logout',
        name: 'logout',
        component: () => import('../views/pages/auth/logout.vue')
    },
    {
        path: '/auth/register',
        name: 'register',
        component: () => import('../views/pages/auth/register.vue')
    },
    {
        path: '/auth/lock-screen',
        name: 'lock-screen',
        component: () => import('../views/pages/auth/lock-screen.vue')
    },
    {
        path: '/auth/recoverpwd',
        name: 'recoverpwd',
        component: () => import('../views/pages/auth/recoverpwd.vue')
    },
    {
        path: '/auth/confirm-mail',
        name: 'confirm-mail',
        component: () => import('../views/pages/auth/confirm-mail.vue')
    },
    {
        path: '/auth/two-step-verification',
        name: 'two-step-verification',
        component: () => import('../views/pages/auth/two-step-verification.vue')
    },
    {
        path: '/auth/email-verification',
        name: 'email-verification',
        component: () => import('../views/pages/auth/email-verification.vue')
    },
]
