import service from '@/api/menuPoint';
import {loading, handleError} from '@/state/generators/helpers';

export const state = {
    loading: false,
    arrangedLoading: false,
    arranged: [],
    matrix: [],
    auth: {},
    service: service('menuPoints')
}

export const getters = {
    arrangedLoading: (state) => {
        return !!state.arrangedLoading;
    },

    arranged: (state) => {
        return JSON.parse(JSON.stringify(state.arranged))
    },

    matrix: (state) => {
        return JSON.parse(JSON.stringify(state.matrix))
    },

    auth: (state) => {
        return JSON.parse(JSON.stringify(state.auth))
    }
}

export const mutations = {
    'SET_LOADING': function (state, boolean = true) {
        state.loading = boolean
    },

    'SET_ARRANGED_LOADING': function (state, boolean = true) {
        state.arrangedLoading = boolean
    },

    'SET_ARRANGED': function (state, arr) {
        state.arranged = arr
    },

    'SET_MATRIX': function (state, arr) {
        state.matrix = arr
    },

    'SET_AUTH': function (state, arr) {
        const mapped = {}

        const set = (item) => {
            if (item.link && item.link !== '/' && item.roles && item.roles.length) {
                mapped[item.link] = item.roles.map(role => role.key);
            }

            if (item.menu_points && item.menu_points.length) {
                item.menu_points.forEach(set)
            }
        }

        arr.forEach(set)

        state.auth = mapped
    },
}

export const actions = {
    arranged: function ({ commit }, payload) {
        commit('app/SET_LOADING', true, {root: true})
        commit('SET_ARRANGED_LOADING', true)

        return state.service.arranged(Object.assign({with: ['roles', 'roleCategories']}, payload)).then(data => {
            commit('SET_ARRANGED', data.data)
            commit('SET_AUTH', data.data)
            commit('app/SET_LOADING', false, {root: true})
            commit('SET_ARRANGED_LOADING', false)
            return data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    matrix: function ({ commit }, payload) {
        loading(commit)

        return state.service.matrix(payload).then(data => {
            commit('SET_MATRIX', data.data)
            loading(commit, false)
            return data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    clearModule: function ({commit}) {
        commit('SET_MATRIX', [])
        commit('SET_ARRANGED', [])
    },
}
