import axios from './axios'

function service(endpoint) {

    function fetchAuthenticated() {
        return axios.get(`${endpoint}/authenticated`).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function changePassword (id, passwords) {
        return axios.post(`${endpoint}/${id}/change-password`, passwords).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function syncRoles (id, data) {
        return axios.post(`${endpoint}/${id}/roles`, data).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function syncRoleGroups (id, data) {
        return axios.post(`${endpoint}/${id}/roleGroups`, data).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    return {
        fetchAuthenticated,
        changePassword,
        syncRoles,
        syncRoleGroups
    }
}

export default service
